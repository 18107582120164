class Audio {
    constructor() {
      this.audioCtx = new (window.AudioContext || window.webkitAudioContext)();
      this.destination = this.audioCtx.createGain();
      this.volume = 1;
      this.destination.connect(this.audioCtx.destination);
      this.files = {};
      this.currentSource = null;
      this.isPlaying = false;
    }
  
    get volume() {
      return this.destination.gain.value;
    }
  
    set volume(level) {
      this.destination.gain.value = level;
    }
  
    play(src) {
      if (!this.isPlaying) {
        this.load(src, "theme", (key) => {
          this.currentSource = this.audioCtx.createBufferSource();
          this.currentSource.buffer = this.files[key];
          this.currentSource.connect(this.destination);
          this.currentSource.loop = true;
          this.currentSource.onended = () => {
            this.isPlaying = false;
          };
          this.currentSource.start(0);
          this.isPlaying = true;
        });
      }
    }
  
    stop() {
      if (this.isPlaying && this.currentSource) {
        this.currentSource.stop();
        this.isPlaying = false;
      }
    }
  
    load(src, key, callback) {
      let _self = this;
      let request = new XMLHttpRequest();
      request.open("GET", src, true);
      request.responseType = "arraybuffer";
      request.onload = function () {
        _self.audioCtx.decodeAudioData(
          request.response,
          function (beatportBuffer) {
            _self.files[key] = beatportBuffer;
            callback(key);
          },
          function () {}
        );
      };
      request.send();
    }
  }
  
  export default Audio;
  