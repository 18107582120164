import React, { useEffect, useState } from 'react';
import '../../styles/Game.css';
import affiche1 from '../../assets/images/affiche1.png';
import affiche2 from '../../assets/images/affiche2.png';
import affiche3 from '../../assets/images/affiche3.png';
import tyres from '../../assets/images/tyres.png';
import toyotaCar from '../../assets/images/hero.png';
import oppsCar from '../../assets/images/car04.png';
import finish from '../../assets/images/finish.png';
import sky from '../../assets/images/cloud.jpg';
import palmier from '../../assets/images/palmier.png';
import themeSong from '../../assets/sound/theme.mp3';
import cocktailItem from '../../assets/images/cocktail.gif';
import toyota from '../../assets/images/toyota.gif';
import sideCar from '../../assets/images/side_car.png';
import sideCar2 from '../../assets/images/side_car2.png';


export default function Game() {
  useEffect(() => {
    const ASSETS = {
      COLOR: {
        TAR: ["#191918", "#191918"],
        RUMBLE: ["#e4002b", "#d0d0d0"],
        GRASS: ["#4e4e4e", "#292929"],
      },

      IMAGE: {
        TYRE: {
          src: tyres,
          width: 132,
          height: 192,
        },

        BILLBOARD: {
          src: affiche1,
          width: 300,
          height: 169,
        },

        BILLBOARD2: {
          src: affiche2,
          width: 300,
          height: 200,
        },

        BILLBOARD3: {
          src: affiche3,
          width: 300,
          height: 169,
        },

        HERO: {
          src: toyotaCar,
          width: 110,
          height: 56,
        },

        CAR: {
          src: oppsCar,
          width: 60,
          height: 43,
        },

        FINISH: {
          src: finish,
          width: 339,
          height: 180,
          offset: -0.5,
        },

        SKY: {
          src: sky,
        },

        PALMER: {
          src: palmier,
          width: 200,
          height: 329,
        },

        COCKTAIL: {
          src: cocktailItem,
          width: 200,
          height: 113,
        },
        TOYOTAITEM: {
          src: toyota,
          width: 200,
          height: 113,
        },
        SIDECAR: {
          src: sideCar,
          width: 195,
          height: 65,
        },
        SIDECAR2: {
          src: sideCar2,
          width: 195,
          height: 60,
        }
      },

      AUDIO: {
        theme: themeSong
      },
    };

    const hero = document.getElementById('hero');
    const text = document.getElementById('text');
    const road = document.getElementById('road');
    const home = document.getElementById('home');
    const hud = document.getElementById('hud');
    const tacho = document.getElementById('tacho');
    const time = document.getElementById('time');
    const lap = document.getElementById('lap');
    const cloud = document.getElementById('cloud');
    const game = document.getElementById('game');
    const highscore = document.getElementById('highscore');
    // ------------------------------------------------------------
    // helper functions
    // ------------------------------------------------------------

    Number.prototype.pad = function (numZeros, char = 0) {
      let n = Math.abs(this);
      let zeros = Math.max(0, numZeros - Math.floor(n).toString().length);
      let zeroString = Math.pow(10, zeros)
        .toString()
        .substr(1)
        .replace(0, char);
      return zeroString + n;
    };

    Number.prototype.clamp = function (min, max) {
      return Math.max(min, Math.min(this, max));
    };

    const timestamp = (_) => new Date().getTime();
    const accelerate = (v, accel, dt) => v + accel * dt;
    const isCollide = (x1, w1, x2, w2) => (x1 - x2) ** 2 <= (w2 + w1) ** 2;


    function getRand(min, max) {
      return (Math.random() * (max - min) + min) | 0;
    }

    function randomProperty(obj) {
      let keys = Object.keys(obj);
      return obj[keys[(keys.length * Math.random()) << 0]];
    }

    function drawQuad(element, layer, color, x1, y1, w1, x2, y2, w2) {
      element.style.zIndex = layer;
      element.style.background = color;
      element.style.top = y2 + `px`;
      element.style.left = x1 - w1 / 2 - w1 + `px`;
      element.style.width = w1 * 3 + `px`;
      element.style.height = y1 - y2 + `px`;

      let leftOffset = w1 + x2 - x1 + Math.abs(w2 / 2 - w1 / 2);
      element.style.clipPath = `polygon(${leftOffset}px 0, ${leftOffset + w2
        }px 0, 66.66% 100%, 33.33% 100%)`;
    }

    const KEYS = {};
    const keyUpdate = (e) => {
      KEYS[e.code] = e.type === `keydown`;
      e.preventDefault();
    };
    document.addEventListener(`keydown`, keyUpdate);
    document.addEventListener(`keyup`, keyUpdate);

    function sleep(ms) {
      return new Promise(function (resolve, reject) {
        setTimeout((_) => resolve(), ms);
      });
    }

    // ------------------------------------------------------------
    // objects
    // ------------------------------------------------------------

    class Line {
      constructor() {
        this.x = 0;
        this.y = 0;
        this.z = 0;

        this.X = 0;
        this.Y = 0;
        this.W = 0;

        this.curve = 0;
        this.scale = 0;

        this.elements = [];
        this.special = null;
      }

      project(camX, camY, camZ) {
        this.scale = camD / (this.z - camZ);
        this.X = (1 + this.scale * (this.x - camX)) * halfWidth;
        this.Y = Math.ceil(((1 - this.scale * (this.y - camY)) * height) / 2);
        this.W = this.scale * roadW * halfWidth;
      }

      clearSprites() {
        for (let e of this.elements) e.style.background = "transparent";
      }

      drawSprite(depth, layer, sprite, offset) {
        let destX = this.X + this.scale * halfWidth * offset;
        let destY = this.Y + 4;
        let destW = (sprite.width * this.W) / 265;
        let destH = (sprite.height * this.W) / 265;

        destX += destW * offset;
        destY += destH * -1;

        let obj = layer instanceof Element ? layer : this.elements[layer + 6];
        obj.style.background = `url('${sprite.src}') no-repeat`;
        obj.style.backgroundSize = `${destW}px ${destH}px`;
        obj.style.left = destX + `px`;
        obj.style.top = destY + `px`;
        obj.style.width = destW + `px`;
        obj.style.height = destH + `px`;
        obj.style.zIndex = depth;
      }
    }

    class Car {
      constructor(pos, type, lane) {
        this.pos = pos;
        this.type = type;
        this.lane = lane;

        var element = document.createElement("div");
        road.appendChild(element);
        this.element = element;
      }
    }
    // ------------------------------------------------------------
    // global varriables
    // ------------------------------------------------------------

    const highscores = [];

    let width = window.innerWidth;
    const halfWidth = width / 2;
    let height = window.innerHeight;
    const roadW = 4000;
    const segL = 200;
    const camD = 0.2;
    const H = 1500;
    const N = 70;

    const maxSpeed = 130;
    const accel = 30;
    const breaking = -80;
    const decel = -40;
    const maxOffSpeed = 40;
    const offDecel = -70;
    const enemy_speed = 3;
    const hitSpeed = 20;

    const LANE = {
      A: -2.3,
      B: -0.5,
      C: 1.2,
    };

    const mapLength = 9000;

    // loop
    let then = timestamp();
    const targetFrameRate = 1000 / 25; // in ms

    let audio;

    // game
    let inGame,
      start,
      playerX,
      speed,
      scoreVal,
      pos,
      cloudOffset,
      sectionProg,
      mapIndex,
      countDown;
    let lines = [];
    let cars = [];

    // ------------------------------------------------------------
    // map
    // ------------------------------------------------------------

    function getFun(val) {
      return (i) => val;
    }

    function genMap() {
      let map = [];

      for (var i = 0; i < mapLength; i += getRand(0, 50)) {
        let section = {
          from: i,
          to: (i = i + getRand(300, 600)),
        };

        let randHeight = getRand(-5, 5);
        let randCurve = getRand(5, 30) * (Math.random() >= 0.5 ? 1 : -1);
        let randInterval = getRand(20, 40);

        if (Math.random() > 0.9)
          Object.assign(section, {
            curve: (_) => randCurve,
            height: (_) => randHeight,
          });
        else if (Math.random() > 0.8)
          Object.assign(section, {
            curve: (_) => 0,
            height: (i) => Math.sin(i / randInterval) * 1000,
          });
        else if (Math.random() > 0.8)
          Object.assign(section, {
            curve: (_) => 0,
            height: (_) => randHeight,
          });
        else
          Object.assign(section, {
            curve: (_) => randCurve,
            height: (_) => 0,
          });

        map.push(section);
      }

      map.push({
        from: i,
        to: i + N,
        curve: (_) => 0,
        height: (_) => 0,
        special: ASSETS.IMAGE.FINISH,
      });
      map.push({ from: Infinity });
      return map;
    }

    let map = genMap();


    // ------------------------------------------------------------
    // additional controls
    // ------------------------------------------------------------
    document.getElementById("startButton").addEventListener("click", function () {
      sleep(0)
        .then((_) => {
          text.classList.remove("blink");
          text.innerText = 3;
          // audio.play("beep");
          return sleep(1000);
        })
        .then((_) => {
          text.innerText = 2;
          // ("beep");
          return sleep(1000);
        })
        .then((_) => {
          reset();
          home.style.display = "none";
          road.style.opacity = 1;
          hero.style.display = "block";
          hud.style.display = "block";
          inGame = true;
        });
    });
    document.addEventListener(`keyup`, function (e) {
      if (e.code === "KeyM") {
        e.preventDefault();
        return;
      }

      if (e.code === "KeyC") {
        e.preventDefault();
        sleep(0)
          .then((_) => {
            text.classList.remove("blink");
            text.innerText = 3;
            return sleep(1000);
          })
          .then((_) => {
            text.innerText = 2;
            return sleep(1000);
          })
          .then((_) => {
            reset();

            home.style.display = "none";

            road.style.opacity = 1;
            hero.style.display = "block";
            hud.style.display = "block";
            inGame = true;
          });

        return;
      }

      if (e.code === "Escape") {
        e.preventDefault();

        reset();
      }
    });

    // ------------------------------------------------------------
    // game loop
    // ------------------------------------------------------------

    // function sendScoretoDB(score, token) {
    //   fetch(`${process.env.REACT_APP_API_URL}/updatescore`, {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({ score, token }),
    //   })
    //     .then((res) => {
    //       if (res.ok) {
    //         sleep(2000).then((_) => {
    //           window.location.href = "/end";
    //         });
    //     } else {
    //       console.log("Score not sent to DB");
    //     }
    //   })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // }

    // function handleGameEnd() {
    //   const lapTime = highscores[0];
    //   const [minutes, seconds, milliseconds] = lapTime.split(/[:.'"]+/);
    //   const scoreInSeconds = parseInt(minutes, 10) * 60 + parseInt(seconds, 10) + parseFloat(milliseconds) / 1000;
    //   const token = localStorage.getItem('userToken');
    //   if (token) {
    //     sendScoretoDB(scoreInSeconds, token);
    //   }
    // }

    function update(step) {
      pos += speed;
      while (pos >= N * segL) pos -= N * segL;
      while (pos < 0) pos += N * segL;

      var startPos = (pos / segL) | 0;
      let endPos = (startPos + N - 1) % N;

      scoreVal += speed * step;
      countDown -= step;

      // left / right position
      playerX -= (lines[startPos].curve / 5000) * step * speed;

      if (KEYS.ArrowRight) {
        hero.style.backgroundPosition = "-220px 0";
        playerX += 0.007 * step * speed;
      } else if (KEYS.ArrowLeft) {
        hero.style.backgroundPosition = "0 0";
        playerX -= 0.007 * step * speed;
      } else {
        hero.style.backgroundPosition = "-110px 0";
      }
      if (playerX < -0.55) {
        playerX = -0.55;
      }

      if (playerX > 0.55) {
        playerX = 0.55;
      }
      let leftButton = document.getElementById("leftButton");
      let middleButton = document.getElementById("middleButton");
      let rightButton = document.getElementById("rightButton");

      leftButton.addEventListener("touchstart", function () {
        hero.style.backgroundPosition = "0 0";
        playerX = -0.350;
      });

      middleButton.addEventListener("touchstart", function () {
        hero.style.backgroundPosition = "-110px 0";
        playerX = 0;
      });

      rightButton.addEventListener("touchstart", function () {
        hero.style.backgroundPosition = "-220px 0";
        playerX = 0.350;
      });

      leftButton.addEventListener("touchend", function () {
        setTimeout(function () { hero.style.backgroundPosition = "-110px 0"; }, 200);
      });

      rightButton.addEventListener("touchend", function () {
        setTimeout(function () { hero.style.backgroundPosition = "-110px 0"; }, 200);
      });

      playerX = playerX.clamp(-3, 3);

      // speed
      speed = accelerate(speed, accel, step);

      if (KEYS.ArrowDown) speed = accelerate(speed, breaking, step);
      else speed = accelerate(speed, decel, step);



      if (Math.abs(playerX) > 0.55 && speed >= maxOffSpeed) {
        speed = accelerate(speed, offDecel, step);
      }
      speed = accelerate(speed, accel, step);


      speed = speed.clamp(0, maxSpeed);

      // update map
      let current = map[mapIndex];
      let use = current.from < scoreVal && current.to > scoreVal;
      if (use) sectionProg += speed * step;
      lines[endPos].curve = use ? current.curve(sectionProg) : 0;
      lines[endPos].y = use ? current.height(sectionProg) : 0;
      lines[endPos].special = null;

      if (current.to <= scoreVal) {
        mapIndex++;
        sectionProg = 0;

        lines[endPos].special = map[mapIndex].special;
      }

      // win / lose + UI

      if (!inGame) {
        speed = accelerate(speed, breaking, step);
        speed = speed.clamp(0, maxSpeed);
      } else if (countDown <= 0 || lines[startPos].special) {
        tacho.style.display = "none";
        home.style.display = "block";
        road.style.opacity = 0.4;
        highscores.push(lap.innerText);
        console.log('New score: ', lap.innerText);
        highscores.sort();
        updateHighscore();
        inGame = false;
        // handleGameEnd();
      } else {
        time.innerText = (countDown | 0).pad(3);
        tacho.innerText = speed | 0;

        let cT = new Date(timestamp() - start);
        lap.innerText = `${cT.getMinutes()}'${cT.getSeconds().pad(2)}"${cT
          .getMilliseconds()
          .pad(3)}`;
      }
      // draw cloud
      cloud.style.backgroundPosition = `${(cloudOffset -= lines[startPos].curve * step * speed * 0.13) | 0
        }px 0`;
      for (let car of cars) {
        car.pos = (car.pos + enemy_speed * step) % N;
        if ((car.pos | 0) === endPos) {
          if (speed < 30) car.pos = startPos;
          else car.pos = endPos - 2;
          car.lane = randomProperty(LANE);
        }
        const offsetRatio = 5;
        if (
          (car.pos | 0) === startPos &&
          isCollide(playerX * offsetRatio + LANE.B, 0.5, car.lane, 0.5)
        ) {
          speed = Math.min(hitSpeed, speed);
          // if (inGame) ("honk");
        }
      }
      let maxy = height;
      let camH = H + lines[startPos].y;
      let x = 0;
      let dx = 0;

      for (let n = startPos; n < startPos + N; n++) {
        let l = lines[n % N];
        let level = N * 2 - n;

        // update view
        l.project(
          playerX * roadW - x,
          camH,
          startPos * segL - (n >= N ? N * segL : 0)
        );
        x += dx;
        dx += l.curve;

        // clear assets
        l.clearSprites();

        // first draw section assets
        //if (n % 10 === 0) l.drawSprite(level, 0, ASSETS.IMAGE.TYRE, -2);
        if ((n + 5) % 10 === 0)
          l.drawSprite(level, 0, ASSETS.IMAGE.TYRE, 1.3);
        if (n % 55 === 0) l.drawSprite(level, 0, ASSETS.IMAGE.BILLBOARD, -1.5);
        if (n % 20 === 0) l.drawSprite(level, 0, ASSETS.IMAGE.BILLBOARD2, 0.7);
        if (n % 35 === 0) l.drawSprite(level, 0, ASSETS.IMAGE.BILLBOARD3, 0.7);
        if (n % 40 === 0) l.drawSprite(level, 0, ASSETS.IMAGE.PALMER, 0.7);
        if (n % 25 === 0) l.drawSprite(level, 0, ASSETS.IMAGE.PALMER, -1.7);
        if (n % 30 === 0) l.drawSprite(level, 0, ASSETS.IMAGE.COCKTAIL, 1.3);
        if (n % 45 === 0) l.drawSprite(level, 0, ASSETS.IMAGE.TOYOTAITEM, -1.9);
        if (n % 50 === 0) l.drawSprite(level, 0, ASSETS.IMAGE.SIDECAR, -2);
        if (n % 42 === 0) l.drawSprite(level, 0, ASSETS.IMAGE.SIDECAR2, 1.2);
        if (l.special)
          l.drawSprite(level, 0, l.special, l.special.offset || 0);

        for (let car of cars)
          if ((car.pos | 0) === n % N)
            l.drawSprite(level, car.element, car.type, car.lane);

        // update road

        if (l.Y >= maxy) continue;
        maxy = l.Y;

        let even = ((n / 2) | 0) % 2;
        let grass = ASSETS.COLOR.GRASS[even * 1];
        let rumble = ASSETS.COLOR.RUMBLE[even * 1];
        let tar = ASSETS.COLOR.TAR[even * 1];

        let p = lines[(n - 1) % N];

        drawQuad(
          l.elements[0],
          level,
          grass,
          width / 4,
          p.Y,
          halfWidth + 2,
          width / 4,
          l.Y,
          halfWidth
        );
        drawQuad(
          l.elements[1],
          level,
          grass,
          (width / 4) * 3,
          p.Y,
          halfWidth + 2,
          (width / 4) * 3,
          l.Y,
          halfWidth
        );

        drawQuad(
          l.elements[2],
          level,
          rumble,
          p.X,
          p.Y,
          p.W * 1.15,
          l.X,
          l.Y,
          l.W * 1.15
        );
        drawQuad(l.elements[3], level, tar, p.X, p.Y, p.W, l.X, l.Y, l.W);

        if (!even) {
          drawQuad(
            l.elements[4],
            level,
            ASSETS.COLOR.RUMBLE[1],
            p.X,
            p.Y,
            p.W * 0.4,
            l.X,
            l.Y,
            l.W * 0.4
          );
          drawQuad(
            l.elements[5],
            level,
            tar,
            p.X,
            p.Y,
            p.W * 0.35,
            l.X,
            l.Y,
            l.W * 0.35
          );
        }
      }
    }

    window.addEventListener("beforeunload", function () {
      reset();
      window.location.href = "/home";
    });

    // ------------------------------------------------------------
    // init
    // ------------------------------------------------------------

    function reset() {
      inGame = false;

      start = timestamp();
      countDown = map[map.length - 2].to / 10 + 10;

      playerX = 0;
      speed = 0;
      scoreVal = 0;

      pos = 0;
      cloudOffset = 0;
      sectionProg = 0;
      mapIndex = 0;

      for (let line of lines) line.curve = line.y = 0;

      road.style.opacity = 0.4;
      hud.style.display = "none";
      home.style.display = "block";
      tacho.style.display = "block";
    }

    function updateHighscore() {
      let hN = Math.min(12, highscores.length);
      for (let i = 0; i < hN; i++) {
        highscore.children[i].innerHTML = `${(i + 1).pad(2, "&nbsp;")}. ${highscores[i]
          }`;
      }
    }

    function init() {
      // Remove token check
      // const token = localStorage.getItem('userToken');
      // if (!token) {
      //   window.location.href = "/home";
      // }
      game.style.width = width + "px";
      game.style.height = height + "px";

      hero.style.top = (height) - 80 + "px";
      hero.style.left = halfWidth - ASSETS.IMAGE.HERO.width / 2 + "px";
      hero.style.background = `url(${ASSETS.IMAGE.HERO.src})`;
      hero.style.width = `${ASSETS.IMAGE.HERO.width}px`;
      hero.style.height = `${ASSETS.IMAGE.HERO.height}px`;

      cloud.style.backgroundImage = `url(${ASSETS.IMAGE.SKY.src})`;

      cars.push(new Car(0, ASSETS.IMAGE.CAR, LANE.C));
      cars.push(new Car(10, ASSETS.IMAGE.CAR, LANE.B));
      cars.push(new Car(20, ASSETS.IMAGE.CAR, LANE.C));
      cars.push(new Car(50, ASSETS.IMAGE.CAR, LANE.A));

      for (let i = 0; i < N; i++) {
        var line = new Line();
        line.z = i * segL + 270;

        for (let j = 0; j < 6 + 2; j++) {
          var element = document.createElement("div");
          road.appendChild(element);
          line.elements.push(element);
        }

        lines.push(line);
      }

      for (let i = 0; i < 12; i++) {
        var element = document.createElement("p");
        highscore.appendChild(element);
      }
      updateHighscore();
      reset();

      // START GAME LOOP
      (function loop() {
        requestAnimationFrame(loop);
        let now = timestamp();
        let delta = now - then;

        if (delta > targetFrameRate) {
          then = now - (delta % targetFrameRate);
          update(delta / 1000);
        }
      })();
    }
    init();
  })
  return (
    <div>
    </div>
  )
}
