import React from 'react'
import '../../styles/Home.css';
import cltcolor from '../../assets/images/logo_clt.gif';
import cadreGif from '../../assets/images/cadre_gif.png';
import '../../assets/fonts/Megapunch_Italic.ttf';
import '../../assets/fonts/pressStart.ttf';
import mediaGif from '../../assets/images/media_gif2.gif';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function Home() {
  const igUrl = 'https://www.instagram.com/cestlateuuf/';
  const rsvpUrl = 'https://shotgun.live/events/event-342584-copy-1728313782476';
  const radioUrl = 'https://soundcloud.com/cltradio';
  return (
    <div>
      <Helmet>
            <title>C'EST LA TEUF - SOIRÉE À MULHOUSE | CLT</title>
            <meta name="description" content="C'EST LA TEUF (CLT) IS AN INDEPENDENT ONLINE RADIO STATION AND A MUSIC EVENT CURATION PLATFORM BASED IN MULHOUSE, FRANCE." />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://cestlateuf.com/home"/>
        </Helmet>
      <div className='clt-tube-border'>
        <Link to='/' style={{ textDecoration: 'none' }}>
          <img src={cltcolor} alt='enjoy the ride clt gif' style={spinningGifStyles}></img>
        </Link>
        <a href={igUrl} style={{ cursor: 'pointer' }}>
          <img src={mediaGif} alt='enjoy the ride clt gif' style={mediaStyles}></img>
        </a>
        <img src={cadreGif} alt='enjoy the ride clt gif' style={cadreGifStyles}></img>
        <div style={textContainer}>
          <Link to='/game' style={{ textDecoration: 'none' }} target='_blank'>
            <p style={aboutStyles}>PLAY</p>
          </Link>
          <a href={rsvpUrl} style={{ textDecoration: 'none' }} target='_blank'>
            <p style={rsvpStyles}>BILLETERIE</p>
          </a>
          <Link to='/about' style={{ textDecoration: 'none' }}>
            <p style={contactStyles}>INFOS</p>
          </Link>

          <a href={radioUrl} style={{ textDecoration: 'none' }} target='_blank'>
            <p style={creditsStyles}>CLT RADIO</p>
          </a>
        </div>
      </div>
    </div>
  )
}

const textContainer = {
  display: 'flex',
  justifyContent: 'space-between',
}

const aboutStyles = {
  fontFamily: 'PressStart',
  fontSize: '22px',
  color: "black",
  bottom: '20%',
  left: '18%',
  position: 'absolute',
}

const contactStyles = {
  fontFamily: 'PressStart',
  fontSize: '22px',
  color: "black",
  bottom: '11%',
  left: '18%',
  position: 'absolute',
}

const creditsStyles = {
  fontFamily: 'PressStart',
  fontSize: '22px',
  color: "black",
  bottom: '11%',
  right: '18%',
  position: 'absolute',
}

const rsvpStyles = {
  fontFamily: 'PressStart',
  fontSize: '22px',
  color: "black",
  bottom: '20%',
  right: '18%',
  position: 'absolute',
}

const mediaStyles = {
  top: '40%',
  left: '50%',
  transform: 'translateX(-50%)',
  position: 'absolute',
  height: '22vh',
  width: 'auto',
}

const spinningGifStyles = {
  top: '8%',
  left: '50%',
  transform: 'translateX(-50%)',
  position: 'absolute',
  height: '30vh',
  width: 'auto',
}

const cadreGifStyles = {
  top: '35%',
  left: '50%',
  transform: 'translateX(-50%)',
  position: 'absolute',
  height: '31vh',
  width: 'auto',
}
