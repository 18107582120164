import React, { useEffect, useRef, useState } from 'react'
import Game from './Game';
import '../../styles/Game.css';
import Audio from './Audio';
import spinning_fest from '../../assets/images/spinning_fest.gif';
import middleIcon from '../../assets/images/center.png';
import leftIcon from '../../assets/images/left.png';
import rightIcon from '../../assets/images/right.png';
import insertCoin from '../../assets/images/INSERT_COIN.png';
import themeSong from '../../assets/sound/theme.mp3';

export default function GameHtml() {
    const audioRef = useRef(new Audio());

  const handleInsertCoinClick = () => {
    audioRef.current.play(themeSong);
  };
    return (
        <div>
            <div id="game">
                <div id="road">
                    <div id="cloud"></div>
                    <div id="hero"></div>
                    <div id="cocktail"></div>
                    <img src={leftIcon} id='leftButton' style={leftIconStyle} />
                    <img src={middleIcon} id='middleButton' style={middleIconStyle} />
                    <img src={rightIcon} id='rightButton' style={rightIconStyle} />
                </div>
                <div id="hud">
                    <span id="time" className="topUI">0</span>
                    <span id="lap" className="topUI">0'00"000</span>
                    <span id="tacho">0</span>
                </div>
                <div id="home">
                    <img src={insertCoin} id='startButton' style={startButtonStyle} onClick={handleInsertCoinClick}/>
                    <img src={spinning_fest} alt="enjoytheride" id="spinning-logo" />
                    <p id="text"></p>
                    <div id="highscore"></div>
                </div>
            </div>
            <Game />
        </div>
    )
}

const startButtonStyle = {
    position: 'absolute',
    bottom: '35%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    height: 'auto',
    animation: 'blinker 1.5s steps(14, end) infinite',
}

const middleIconStyle = {
    position: 'absolute',
    bottom: '15%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '15%',
    height: 'auto',
    zIndex: '6000',
};

const leftIconStyle = {
    position: 'absolute',
    bottom: '20%',
    left: '10%',
    width: '15%',
    height: 'auto',
    zIndex: '6000',
};

const rightIconStyle = {
    position: 'absolute',
    bottom: '20%',
    right: '10%',
    width: '15%',
    height: 'auto',
    zIndex: '6000',
};