import './App.css';
import { Route, Routes } from 'react-router-dom';
import Landing from './components/Landing/Landing';
import Home from './components/Home/Home';
import About from './components/About/About';
import GameHtml from './components/Game/GameHtml';
import Leaderboard from './components/Game/Leaderboard';
import End from './components/End/End';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Landing />} />
        <Route path='/home' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/game' element={<GameHtml />} />
        <Route path='/leaderboard' element={<Leaderboard />} />
        <Route path='/end' element={<End />} />
      </Routes>
    </div>
  );
}

export default App;